
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
    .buttonText{
        color: rgb(77, 90, 255);
    }
    /*::v-deep.el-tab-pane{*/
    /*    height: 100%;*/
    /*}*/
    .viewDetails{
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);
        background: #fff;
        .breadcrumb-box{
          background: #F3F3F6;
          display: flex;
          justify-content: space-between;
          ::v-deep.el-button--primary {
            height: 36px;
            line-height: 0;
            color: #FFF;
            background-color:#0824ce;
            border-color: #0824ce;
          }
          ::v-deep.el-button--primary:focus, .el-button--primary:hover {
            background: #495df1;
            border-color: #495df1;
            color: #FFF;
          }
        }
        .details-header{
            padding: 10px;
            display: flex;
            flex-direction: column;
            background: #fff;
            .header-title{
              color: #333;
              font-size: 16px;
              padding: 20px 26px;
              border-bottom: 1px solid #E5E5E5;
            }
            .header-time{
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #E5E5E5;
              padding-bottom: 20px;
              .time-left{
                display: flex;
                flex-direction: column;
                span{
                  padding-left: 34px;
                  font-size: 14px;
                  color: #666666;
                  line-height: 1;
                  margin-top: 22px;
                  span{
                    &:after{
                      content:'、'
                    }
                    &:last-child{
                      &:after{
                        content:''
                      }
                    }
                  }
                  &:nth-child(2){
                    strong{
                      color: #FF0000;
                      font-weight: 400;
                    }
                  }
                }
              }
              .status-btn{
                display: flex;
                align-items: center;
                margin-right: 20px;
                .btn1{
                    color: #FFF;
                    background-color:#9ACC5B;
                    border-color: #9ACC5B;
                }
                .btn2{
                  background: #DCDCDC;
                  color: #333333;
                  border-color: #DCDCDC;
                }
              }
            }
            .demand-desc{
              border-bottom: 1px solid #EEEEEE;
              //overflow-y: hidden;
              padding-left: 35px;
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              //::v-deep .el-scrollbar__wrap {
              //  overflow-x: hidden;
              //}
              //::v-deep.el-scrollbar__thumb{
              //  display: none;
              //}
              .desc-title{
                display: flex;
                align-items: center;
                .title-block{
                  width: 3px;
                  height: 10px;
                  background: #00B47F;
                  margin-right: 6px;
                }
                span{
                  font-size: 16px;
                }
              }
              .title-p{
                padding: 10px 0;
              }
              .script-table{
                margin-bottom: 30px;
                display: flex;
                flex-direction: column;
                margin-right: 150px;
                .script-table-row{
                  display: flex;
                  &:nth-child(1){
                    line-height: 1;
                    .script-table-column{
                      padding: 18px 0;
                    }
                  }
                  .script-table-column{
                    display: flex;
                    flex: 1;
                    width: 1%;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 0;
                    margin-right: 3px;
                  }
                  &:nth-child(odd){
                    .script-table-column{
                      background: #D3DDFF;
                    }
                  }
                  &:nth-child(even){
                    .script-table-column{
                      background: #F0F3FF;
                    }
                  }
                }
              }
            }
          .accessory{
            margin-top: 16px;
            padding-left: 35px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #EEEEEE;
            .desc-title{
              display: flex;
              align-items: center;
              .title-block{
                width: 3px;
                height: 10px;
                background: #00B47F;
                margin-right: 6px;
              }
              span{
                font-size: 16px;
              }
            }
            .accessory-name{
              font-size: 14px;
              margin-top: 20px;
            }
            .script-box{
              .script-item{
                display: flex;
                align-items: center;
                line-height: 1;
                margin-top: 20px;
                cursor: pointer;
                i{
                  font-size: 16px;
                  color: #409EFF;
                  margin-right: 10px;
                }
                span{
                  font-size: 14px;
                }
              }
            }
            .material-box{
              display: flex;
              flex-wrap: wrap;
              padding-top: 20px;
              .material-item{
                margin-top: 10px;
                display: flex;
                align-items: center;
                width: 33%;
                justify-content: space-around;
                .item-left{
                  display: flex;
                  align-items: center;
                  i{
                    font-size: 18px;
                    color: #409EFF;
                    margin-right: 7px;
                    cursor: pointer;
                  }
                  span{
                    font-size: 18px;
                  }
                }
                .item-right{
                  i{
                    font-size: 22px;
                    color: #409EFF;
                  }
                }
              }
            }
            .evaluate-table{
              margin-top: 30px;
              margin-left: 80px;
              width: 70%;
              display: flex;
              flex-direction: column;
              .evaluate-row{
                display: flex;
                &:nth-child(even){
                  .evaluate-column{
                    background: #F0F3FF;
                  }
                }
                &:nth-child(odd){
                  .evaluate-column{
                    background: #D3DDFF;
                  }
                }
                &:nth-child(1){
                  height: 50px;
                }
                .evaluate-column{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 4px;
                  padding: 16px 0;
                  &:nth-child(1){
                    width: 64px;
                  }
                  &:nth-child(2){
                    width: 330px;
                  }
                  &:nth-child(3){
                    display: flex;
                    flex: 1;
                    width: 1%;
                  }
                  &:nth-child(4){
                    width: 80px;
                  }
                }
              }
            }
          }
        }
      .unfold-content{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2338E6;
        font-size: 14px;
        line-height: 1;
        padding: 20px 0 30px 0;
        span{
          cursor: pointer;
        }
      }
        .submissions{
            background: #ffffff;
            overflow: hidden;
            .submissions-title{
                margin: 24px;
                color: #333333;
                font-size: 16px;
                font-weight:400;
            }
            .class-select{
                font-size: 14px;
                span{
                    display: inline-block;
                    color: #333333;
                    margin-left: 24px;
                    margin-bottom: 30px;
                }
                ::v-deep .el-select{
                    height: 28px;
                    margin-left: 10px;
                    width: 135px;
                }

            }
            .tableList{
                span{
                    color: #666666;
                    font-size:14px;
                    font-weight: 400;
                }
            }
        }
    }
    .dialog-footer {
        .el-button {
            padding: 8px 34px;
        }
        .el-button--primary {
            background-color: #2338E6;
            border: 1px solid #2338E6;
            &:hover {
                background-color: #1b2dbf;
                border: 1px solid #1b2dbf;
            }
        }
    }
    .annex-list {
        .annex-item {
            display: flex;
            margin-bottom: 15px;
            .annex-name {
                float: left;
                display: block;
                max-width: 300px;
                margin-right: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                border-bottom:1px solid transparent;
                color: #000;
                &:hover {
                    border-bottom:1px solid #2338E6;
                    color: #2338E6;
                }
            }
            .download {
                color: #2338E6;
                width: 50px;
                margin-right: 20px;
                cursor: pointer;
                border-bottom:1px solid transparent;
                &:hover {
                    border-bottom:1px solid #2338E6;
                }
            }
        }
    }
    .annex-list-dialog {
        ::v-deep .el-dialog__header {
            border-bottom: 1px solid #EAEAEA;
            ::v-deep .el-dialog__title {
                font-size: 14px;
            }
        }
        ::v-deep .el-dialog__body {
            padding: 20px 30px;
        }
        ::v-deep .el-dialog__footer {
            text-align: center;
        }
        .annex-content {
            height: 257px;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    /*height: 100%;*/
                }
            }
        }
        .annex-user {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            .user {
                color: #333;
                margin-bottom: 10px;
            }
            .time {
                color: #666666;
                font-size: 12px;
            }
        }
    }
    .detailBox{
      display: flex;
      flex-direction: column;
      height: 100%;
      //background: #fff;
      ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    ::v-deep.script-dialog{
      border-radius: 6px;
      .el-dialog__header{
        display: none;
      }
      .el-dialog__body{
        padding: 30px;
      }
      .script-dialog-box{
        display: flex;
        flex-direction: column;
        .script-dialog-header{
          display: flex;
          .header-left{
            img{
              max-height: 226px;
              max-width: 390px;
              border-radius: 10px;
            }
          }
          .header-right{
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            flex: 1;
            width: 1%;
            span{
              font-weight: 400;
              &:nth-child(1){
                color: #333333;
                font-size: 18px;
                margin-top: 10px;
              }
              &:nth-child(2){
                color: #666666;
                font-size: 16px;
                margin-top: 30px;
              }
              &:nth-child(3){
                color: #666666;
                font-size: 16px;
                margin-top: 8px;
                line-height: 30px;
              }
            }
          }
        }
        .script-dialog-content{
          display: flex;
          padding: 40px 20px;
          border: 2px solid #584EEE;
          border-radius: 20px;
          margin-top: 48px;
          position: relative;
          .content-title{
            padding: 0 22px;
            display: flex;
            height: 32px;
            align-items: center;
            background: #584EEE;
            border-radius: 16px;
            position: absolute;
            left: 42px;
            top: -16px;
            span{
              font-size: 16px;
              color: #F7F7F7;
              line-height: 1;
              margin: 0 20px;
            }
            .title-dot{
              width: 6px;
              height: 6px;
              background: #fff;
              border-radius: 50%;
            }
          }
        }
        .script-dialog-table{
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          height: 265px;
          ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
          }
          .dialog-table-row{
            display: flex;
            margin-bottom: 3px;
            &:nth-child(odd){
              background: #DEDCFD;
            }
            &:nth-child(even){
              background: #F8F7FF;
            }
            &:nth-child(1){
              .dialog-table-column{
                font-size: 14px;
                color: #222222;
                font-weight: 400;
              }
            }
            .dialog-table-column{
              display: flex;
              flex: 1;
              width: 1%;
              justify-content: center;
              align-items: center;
              height: 50px;
            }
          }
        }
        .script-dialog-btn{
          display: flex;
          justify-content: center;
          margin-top: 68px;
        }
      }
    }
::v-deep.el-rate .el-rate__item{
  .el-rate__icon{
    font-size: 17px;
    margin-right: 0;
  }
}
.is-qualified, .no-qualified{
  font-size: 38px;
  line-height: 1;
  &.is-qualified{
    color: #20C997;
  }
  &.no-qualified{
    color: #FF0000;
  }
}
